import horizontalLogo from "../../assets/images/headerHorizontalLogo.webp";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
    footerContainerStyle,
    linkStyle,
    linkHeaderStyle,
    imgContainerStyle,
} from "./Footer.style";
import useMessage from "src/hooks/useMessage";
import { useNavigate } from "react-router-dom";

function Footer() {
    const { showSuccess } = useMessage();
    const navigate = useNavigate();

    const phoneNumber = "+1 646 8139726";
    const email = "info@autobackorder.com";

    const handlePhoneClick = async () => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(phoneNumber);
                showSuccess("Phone number copied to clipboard!");
            }
        } catch (error) {
            console.error("Failed to copy phone number:", error);
        }
    };

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };

    return (
        <Grid
            container
            sx={footerContainerStyle}
            columnSpacing={{ xs: 2, md: 0 }}
        >
            <Grid item sx={imgContainerStyle}>
                <img
                    src={horizontalLogo}
                    alt="Logo"
                    loading="lazy"
                    onClick={() => navigate("/")}
                    style={{
                        cursor: "pointer",
                        width: "200px",
                        height: "70px",
                    }}
                />
            </Grid>
            <Grid item container xs={3} md={2} rowSpacing={1}>
                <Grid item xs={12}>
                    <Typography sx={linkHeaderStyle}>
                        {"Quick Links"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        onClick={() => navigate("/about-us")}
                        sx={linkStyle}
                    >
                        {"About Us"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        onClick={() => navigate("/how-it-works")}
                        sx={linkStyle}
                    >
                        {"How it Works"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        onClick={() => navigate("/price")}
                        sx={linkStyle}
                    >
                        {"Prices"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={3} md={2} rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography sx={linkHeaderStyle}>{"Legal"}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        onClick={() => navigate("/privacy")}
                        sx={linkStyle}
                    >
                        {"Privacy"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography onClick={() => navigate("/tos")} sx={linkStyle}>
                        {"Terms of Service"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography onClick={() => navigate("/tac")} sx={linkStyle}>
                        {"Terms and Conditions"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={3} md={2} rowSpacing={0}>
                <Grid item xs={12}>
                    <Typography sx={linkHeaderStyle}>{"Contact"}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        sx={linkStyle}
                        onClick={() => handlePhoneClick()}
                    >
                        {phoneNumber}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        sx={linkStyle}
                        onClick={() => handleEmailClick()}
                    >
                        {email}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Footer;
