import backgroundFooter from "../../assets/images/backgroundFooter.webp";
import backgroundFooterM from "../../assets/images/backgroundFooterTop.webp";

export const footerContainerStyle = {
    backgroundColor: "#FEFFF1",
    marginTop: 0,
    paddingY: 3,
    justifyContent: "center",
    backgroundImage: `url(${backgroundFooter}), url(${backgroundFooterM})`,
    backgroundPosition: "left bottom, right top",
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "50%, 30%", sm: "30%, 28%" },
};

export const linkStyle = {
    color: "rgba(58, 53, 65, 0.8)",
    fontWeight: 500,
    fontSize: { xs: "8px", sm: "14px" },
    lineHeight: { xs: "8px", sm: "14px" },
    pl: "0px",
    whiteSpace: { xs: "normal", sm: "nowrap" },
    cursor: "pointer",
};

export const linkHeaderStyle = {
    fontWeight: 900,
    fontSize: { xs: "12px", sm: "18px" },
    lineHeight: { xs: "12px", sm: "18px" },
    pl: "0px",
    whiteSpace: { xs: "normal", sm: "nowrap" },
    cursor: "default",
};

export const imgContainerStyle = {
    display: { xs: "none", sm: "flex" },
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
};
